import DruidDropdown from '@123/druid/dist/Components/Dropdown/Dropdown';
import tag from '@123/druid/dist/Framework/Decorators/Tag';
import type StringMap from '@123/druid/dist/Types/StringMap';
import * as defaults from '@Component/Dropdown/DropdownDefaultValues';

@tag('dr-dropdown')
export default class Dropdown extends HTMLElement {
    private dropdown?: DruidDropdown;

    get alignment(): string {
        return this.attributeMap().getString('alignment', defaults.alignment);
    }

    get offset(): StringMap {
        return this.attributeMap().getJson('offset', defaults.offset);
    }

    get minWidth(): number {
        return this.attributeMap().getInt('minWidth', defaults.minWidth);
    }

    public connectedCallback(): void {
        this.dropdown = new DruidDropdown({
            context: this,
            dropdown: this.queryRole('dropdown-content'),
            container: this,
            alignment: this.alignment === defaults.alignment ? undefined : this.alignment,
            offset: this.offset === defaults.offset ? undefined : this.offset,
            minWidth: this.minWidth === defaults.minWidth ? undefined : this.minWidth
        });
        this.addEventListener('click.dropdown', this.toggle);
    }

    public disconnectedCallback(): void {
        this.removeEventListeners('click.dropdown');
    }

    public getDropdown(): DruidDropdown {
        if (this.dropdown === undefined) {
            throw new Error('No dropdown found.');
        }

        return this.dropdown;
    }

    private toggle(): void {
        if (this.getDropdown().isShowing()) {
            this.getDropdown().hide();
            this.dispatchEvent(new CustomEvent('dropdownClosed', {bubbles: true, cancelable: false}));
        } else {
            this.getDropdown().show();
            this.dispatchEvent(new CustomEvent('dropdownOpened', {bubbles: true, cancelable: false}));
        }
    }

    static get observedAttributes(): string[] {
        return [];
    }
}
