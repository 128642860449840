import tag from '@123/druid/dist/Framework/Decorators/Tag';
import ResizeHandler from '@Component/CardList/Handler/ResizeHandler';
import type CardListContext from '@Component/CardList/Type/CardListContext';
import CardListLayout from '@Component/CardList/Type/CardListLayout';
import CardListUI from '@Component/CardList/Type/CardListUI';

@tag('dr-card-list')
export default class CardList extends HTMLElement {
    private context?: CardListContext;

    public connectedCallback(): void {
        this.createContext();
        this.getContext().layout.attach();
        this.getContext().resizeHandler.attach();
    }

    public disconnectedCallback(): void {
        this.getContext().layout.detach();
        this.getContext().resizeHandler.detach();
    }

    private createContext(): void {
        const ui            = new CardListUI(this);
        const layout        = new CardListLayout(ui);
        const resizeHandler = new ResizeHandler(layout);

        this.context = {ui, layout, resizeHandler};
    }

    private getContext(): CardListContext {
        if (this.context === undefined) {
            throw new Error('No CardList context found.');
        }

        return this.context;
    }

    static get observedAttributes(): string[] {
        return [];
    }
}
