import type CardList from '@Component/CardList/CardList';
import type Dropdown from '@Component/Dropdown/Dropdown';

export default class CardListUI {
    public cards: HTMLElement[];
    public compact: Dropdown;

    constructor(public readonly element: CardList) {
        this.cards   = Array.from(this.element.queryRoleAll<HTMLElement>('card'));
        this.compact = this.element.queryRole<Dropdown>('compact');
    }
}
