import bind from '@123/druid/dist/Utility/Decorator/Bind';
import type CardListUI from '@Component/CardList/Type/CardListUI';

enum DisplayMode {
    Full = 'full',
    Compact = 'compact'
}

export default class CardListLayout {
    private activeDisplayMode?: DisplayMode;

    constructor(private readonly ui: CardListUI) {
        this.update();
    }

    public attach(): void {
        this.ui.element.addEventListener('dropdownClosed.cardList', this.update);
    }

    public detach(): void {
        this.ui.element.removeEventListeners('dropdownClosed.cardList');
    }

    @bind
    public update(): void {
        if (this.ui.element.clientWidth > this.getListFullWidth()) {
            this.showFullList();
        } else {
            this.showCompactList();
        }
    }

    /**
     ** Get full width of the card list, including:
     ** padding,
     ** border,
     ** margin,
     ** gap (of parent container)
     */
    private getListFullWidth(): number {
        let cardListWidth = 0;
        this.ui.cards.forEach((el: HTMLElement) => {
            const style    = getComputedStyle(el);
            cardListWidth += el.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight);
        });

        // Add gap spacing when there are more than 2 items
        if (this.ui.cards.length > 1) {
            const style    = getComputedStyle(this.ui.element);
            cardListWidth += parseInt(style.gap) * (this.ui.cards.length - 1);
        }

        return cardListWidth;
    }

    private showFullList(): void {
        if (this.activeDisplayMode === DisplayMode.Full || this.ui.compact.getDropdown().isShowing()) {
            return;
        }

        this.ui.element.classList.add('u-active');
        this.ui.compact.classList.add('u-hidden');
        this.activeDisplayMode = DisplayMode.Full;
    }

    private showCompactList(): void {
        if (this.activeDisplayMode === DisplayMode.Compact) {
            return;
        }

        this.ui.element.classList.remove('u-active');
        this.ui.compact.classList.remove('u-hidden');
        this.activeDisplayMode = DisplayMode.Compact;
    }
}
