import bind from '@123/druid/dist/Utility/Decorator/Bind';
import type CardListLayout from '@Component/CardList/Type/CardListLayout';

export default class ResizeHandler {
    private isAttached = false;

    constructor(private readonly layout: CardListLayout) {
    }

    public attach(): void {
        if (this.isAttached) {
            throw new Error('ResizeHandler was already attached.');
        }

        window.addEventListener('resize', this.handleResize);

        this.isAttached = true;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('ResizeHandler was not attached.');
        }

        window.removeEventListener('resize', this.handleResize);

        this.isAttached = false;
    }

    @bind
    private handleResize(): void {
        this.layout.update();
    }
}
